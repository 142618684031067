const obj = {
	checkFirstFourCharInRange: (ssn) => {
		// This check is added to prevent auto adding '19' or '20' in front when valid year is inputed by user
		if (ssn.length === 10) {
			const firstFourChar = ssn.substring(0, 4);
			const firstFourCharToNumber = Number(firstFourChar);
			const d = new Date();
			const y = d.getFullYear();
			const min = y - 150;
			const max = y + 50;
			if (min < firstFourCharToNumber && firstFourCharToNumber < max) {
				return true;
			} else {
				return false;
			}
		}
	}
};

module.exports = obj;
