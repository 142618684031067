const obj = {
	/**
	 * Validates emails.
	 * @param {string} value - The email to validate.
	 * @exports validateEmail
	 */
	validateEmail: (value) => {
		// For some strange reason value can be of type List (immutable) and therefor we have to check that the value is actually a string to prevent errors
		value = value && typeof value === 'string' ? value.trim().replace('demo=', '') : null;
		return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value);
	}
};

module.exports = obj;
