const { INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE } = require('../constants.json');
const validation = require('./validation');

const obj = {
	/**
	 * Add new contact to the investment.
	 *
	 * @param {Object} investment
	 * @param {Object} contact
	 * @returns {Object} the updated investment object
	 */
	createContact: (investment, contact) => {
		if (!investment) {
			return;
		}

		obj.validateContact(contact);

		let investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			investorInformation = {};
		}

		if (!investorInformation.contacts) {
			investorInformation.contacts = [];
		}

		investorInformation.contacts.push(contact);
		investment[obj.getInvestorInformationPath(investment)] = investorInformation;

		return investment;
	},

	/**
	 * Get property name of the investor information.
	 *
	 * @param {Object} investment
	 * @returns {string}
	 */
	getInvestorInformationPath: (investment) => {
		if (!investment.investorTypeOfOwner) {
			return;
		}
		if (investment.investorTypeOfOwner === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE) {
			return 'captialIncuranceOwnerInformation';
		} else {
			return 'investorInformation';
		}
	},

	/**
	 * Get the investorInformation object.
	 *
	 * @param {Object} investment
	 * @returns {Object}
	 */
	getInvestorInformation: (investment) => {
		if (!investment.investorTypeOfOwner) {
			return;
		}
		return investment[obj.getInvestorInformationPath(investment)];
	},

	/**
	 * Get a specific contact.
	 *
	 * @param {Object} investment
	 * @param {uuid} contactId
	 * @returns {Object}
	 */
	getContact: (investment, contactId) => {
		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return;
		}

		const contact =
			investorInformation.contacts && investorInformation.contacts.find((contact) => contact.id === contactId);

		return contact;
	},

	/**
	 * Get the contact marked as primary.
	 *
	 * @param {Object} investment
	 * @returns {Object}
	 */
	getPrimaryContact: (investment) => {
		const { defaultContact } = investment;
		const primaryContact = obj.getContact(investment, defaultContact);

		return primaryContact;
	},

	/**
	 * Get the primary email address.
	 *
	 * @alias getInvestorEmail
	 * @throws {Error} Throw an error if no contact corresponds to the given contact ID
	 * @param {Object} investment
	 * @returns {string}
	 */
	getPrimaryInvestmentEmail: (investment) => {
		return obj.getInvestorEmail(investment);
	},

	/**
	 * Get email address.
	 * If contactId is omitted the primary email address will be returned.
	 *
	 * @param {Object} investment
	 * @returns {string}
	 */
	getInvestorEmail: (investment) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return;
		}

		if (investorInformation.email) {
			return investorInformation.email;
		}

		const primaryContact = obj.getPrimaryContact(investment);
		return primaryContact && primaryContact.email;
	},

	/**
	 * Get name of the investor.
	 *
	 * @param {Object} investment
	 * @returns {string}
	 */
	getInvestorName: (investment) => {
		if (!investment) {
			return;
		}
		const investorInformation = obj.getInvestorInformation(investment);
		if (!investorInformation || !investorInformation.name) {
			return;
		}
		return investorInformation.name;
	},

	/**
	 * Get ssn/org. number/personal ID of the investor.
	 *
	 * @param {Object} investment
	 * @returns {string}
	 */
	getInvestorSsn: (investment) => {
		if (!investment) {
			return;
		}
		const investorInformation = obj.getInvestorInformation(investment);
		if (!investorInformation || !investorInformation.id) {
			return;
		}
		return investorInformation.id;
	},

	/**
	 * Get phone number.
	 * If contactId is omitted the primary phone number will be returned
	 *
	 * @param {Object} investment
	 * @returns {string}
	 */
	getInvestorTelephone: (investment) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return;
		}

		if (investorInformation.telephone) {
			return investorInformation.telephone;
		}

		const primaryContact = obj.getPrimaryContact(investment);
		return primaryContact && primaryContact.phone;
	},

	/**
	 * returns a property from investorInformation or capitalInsuranceInformation
	 *
	 * @param {Object} investment
	 * @param {string} investment
	 * @returns {Object} property
	 */
	getInvestorProperty: (investment, property) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return;
		}
		return investorInformation[property];
	},

	/**
	 *
	 * @param {Object} investment
	 * @returns {string}
	 */
	getInvestorAddressFormatted: (investment) => {
		if (!investment) {
			return '';
		}

		const { address, zip, city, country } = investment.investorInformation;

		if (!address || !city) {
			return '';
		}

		return `${address}, ${zip ? `${zip} ` : ''}${city}${country ? `, ${country}` : ''}`;
	},

	/**
	 * Update contact details.
	 *
	 * @param {Object} investment
	 * @param {Object} contactDetails the contact information to update
	 * @param {uuid} contactId
	 * @returns {Object} updated investment object
	 */
	updateContact: (investment, contactDetails, contactId) => {
		if (!investment) {
			return;
		}

		delete contactDetails.id;

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return investment;
		}

		investorInformation.contacts = investorInformation.contacts.map((contact) => {
			if (contact.id === contactId) {
				return {
					...contact,
					...contactDetails
				};
			}

			return contact;
		});

		investment[obj.getInvestorInformationPath(investment)] = investorInformation;

		return investment;
	},

	/**
	 * Delete a contact.
	 *
	 * @param {Object} investment
	 * @param {uuid} contactId
	 * @returns {Object} updated investment object
	 */
	deleteContact: (investment, contactId) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return investment;
		}

		investorInformation.contacts = investorInformation.contacts.filter((contact) => {
			return contact.id !== contactId;
		});

		investment[obj.getInvestorInformationPath(investment)] = investorInformation;

		return investment;
	},

	/**
	 * Replace investor name.
	 *
	 * @param {Object} investment
	 * @param {string} name
	 * @returns {Object} updated investment object
	 */
	replaceName: (investment, name) => {
		if (!investment) {
			return;
		}

		const investorInformationPath = obj.getInvestorInformationPath(investment);

		if (typeof investment[investorInformationPath] === 'undefined') {
			investment[investorInformationPath] = {};
		}

		investment[investorInformationPath].name = name;

		return investment;
	},

	/**
	 * Replace investor personal ID.
	 *
	 * @param {Object} investment
	 * @param {string} id
	 * @returns {Object} updated investment object
	 */
	replaceSsn: (investment, id) => {
		if (!investment) {
			return;
		}

		const investorInformationPath = obj.getInvestorInformationPath(investment);

		if (typeof investment[investorInformationPath] === 'undefined') {
			investment[investorInformationPath] = {};
		}

		investment[investorInformationPath].id = id;

		return investment;
	},

	/**
	 * Replace email address.
	 *
	 * @param {Object} investment
	 * @param {string} email
	 * @returns {Object} updated investment object
	 */
	replaceEmail: (investment, email) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return investment;
		}

		if (typeof email === 'string' && email.length > 0) {
			investment[obj.getInvestorInformationPath(investment)].email = email;
		} else if (typeof investment.defaultContact === 'string' && investment.defaultContact.length > 0) {
			const contact = this.getContact(investment, investment.defaultContact);

			if (contact.email) {
				investment[obj.getInvestorInformationPath(investment)].email = contact.email;
			}
		}

		return investment;
	},

	/**
	 * Replace telephone number.
	 *
	 * @param {Object} investment
	 * @param {string} telephone
	 * @returns {Object} updated investment object
	 */
	replaceTelephone: (investment, telephone) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return investment;
		}

		if (typeof telephone === 'string' && telephone.length > 0) {
			investment[obj.getInvestorInformationPath(investment)].telephone = telephone;
		} else if (typeof investment.defaultContact === 'string' && investment.defaultContact.length > 0) {
			const contact = this.getContact(investment, investment.defaultContact);
			investment[obj.getInvestorInformationPath(investment)].telephone = telephone;
		}

		return investment;
	},

	/**
	 *
	 * Replace phoneFromProfile, calculated property indicated where the phone number came from (from a registered user or from the shareholder object)
	 * @param {Object} investment
	 * @param {boolean} phoneFromProfile
	 * @returns {Object} updated investment object
	 */
	replacePhoneFromProfile: (investment, phoneFromProfile) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return investment;
		}

		investment[obj.getInvestorInformationPath(investment)].phoneFromProfile = phoneFromProfile;

		return investment;
	},

	/**
	 * Replace property in investorInformation with value.
	 *
	 * @param {Object} investment
	 * @param {string} property
	 * @param {string} value
	 * @returns {Object} updated investment object
	 */
	replaceProperty: (investment, property, value) => {
		if (!investment) {
			return;
		}

		const investorInformation = obj.getInvestorInformation(investment);

		if (!investorInformation) {
			return investment;
		}

		investment[obj.getInvestorInformationPath(investment)][property] = value;

		return investment;
	},

	/**
	 * Set investorInformation object.
	 *
	 * @param {Object} investment
	 * @param {Object} investorInformation
	 * @returns {Object} updated investment object
	 */
	setInvestorInformation: (investment, investorInformation) => {
		const investorInformationPath = obj.getInvestorInformationPath(investment);

		investment[investorInformationPath] = investorInformation;
		return investment;
	},

	/**
	 * Validate contact
	 *
	 * @throws {Error} throw an error if contact is invalid.
	 * @param {Object} contact
	 * @returns {boolean} return true if contact is valid
	 */
	validateContact: (contact) => {
		if (!contact.id) {
			throw new Error('Missing ID');
		}

		// if (typeof contact.email !== 'undefined' && !validation.validateEmail(contact.email)) {
		// 	throw new Error('Email is invalid')
		// }

		return true;
	},

	/**
	 * Check if investment has any email addresses.
	 *
	 * @param {Object} investment
	 * @returns {boolean}
	 */
	hasEmail: (investment) => {
		if (!investment) {
			return false;
		}

		const investorInformation = obj.getInvestorInformation(investment);
		const result =
			investorInformation &&
			(investorInformation.email ||
				(investorInformation.contacts &&
					investorInformation.contacts.some(
						(contact) =>
							contact.id === investment.defaultContact &&
							typeof contact.email === 'string' &&
							contact.email.length > 0
					)));

		return result;
	}
};

module.exports = obj;
