const obj = {
	companyRoles: {
		AK: 'Aktuarie (lagstadgad tjänsteman i livförsäkningsbolag)',
		ARB: 'Arbetstagarrepresentant',
		BO: 'Bolagsman (delägare HB)',
		DELG: 'Delgivningsbar person',
		EFT: 'Extern firmatecknare (ej styrelsemedlem)',
		EVD: 'VD (ej styrelsemedlem)',
		EVVD: 'Vice VD (ej styrelsemedlem)',
		FÖ: 'Föreståndare för filial till utländskt företag',
		IN: 'Innehavare av enskild firma',
		KD: 'Kommanditdelägare med begränsat ansvar i KB',
		KP: 'Komplementär med obegränsat ansvar i KB',
		LE: 'Styrelseledamot i AB',
		LI: 'Likvidator',
		LS: 'Likvidatorsuppleant',
		OF: 'Ordförande',
		PO: 'Prokurist (firmatecknare i HB/KB, ej delägare)',
		REV: 'Revisor',
		REVH: 'Huvudansvarig revisor',
		REVL: 'Lekmannarevisor',
		REVS: 'Revisorsuppleant',
		REVST: 'Aktuarie revisorsuppleant',
		REVT: 'Aktuarie revisor',
		SU: 'Styrelsesuppleant',
		SVD: 'Ställföreträdande VD',
		VD: 'Verkställande direktör',
		VLE: 'Verkställande ledamot',
		VOF: 'Vice ordförande',
		VV: 'Vice verkställande direktör',
		ENSKILD_FIRMA: 'Enskild firma'
	}
};

module.exports = obj;
