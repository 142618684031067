const { ATTENDEE_STATUS_PRESENT, ATTENDEE_STATUS_UNSURE } = require('../constants.json');
const moment = require('../helpers/moment.helper');

const obj = {
	administrativeRoles: {
		chairman: 'Ordförande',
		secretary: 'Sekreterare'
	},

	createAttendeeObject: (attendeeId, isGuest) => {
		return {
			userId: attendeeId,
			isGuest,
			status: null,
			responseStatus: null,
			haveResponded: false,
			notified: false,
			addedAt: moment().toISOString()
		};
	},

	// Create a feedbackee object
	createFeedbackeeObject: (id, isGuest = false, company) => {
		let feedbackee = {
			userId: id,
			isGuest,
			active: true,
			done: false,
			notified: false
		};

		if (company && company.representatives && Array.isArray(company.representatives)) {
			const representative = company.representatives.find((obj) => obj.id === id);

			if (representative) {
				feedbackee.roles = representative.roles;
			}
		}

		return feedbackee;
	},

	createSignatoryObject: (id, isGuest, company) => {
		let signatory = {
			userId: id,
			isGuest,
			active: true,
			status: 'NOT-SIGNED',
			notified: false
		};

		if (company && company.representatives && Array.isArray(company.representatives)) {
			const representative = company.representatives.find((obj) => obj.id === id);

			if (representative) {
				signatory.roles = representative.roles;
			}
		}

		return signatory;
	},

	createProtocolRecipientObject: (recipient) => {
		const object = {
			userId: recipient.userId,
			sharedWith: false
		};

		if (recipient.isGuest) {
			object.isGuest = true;
		}

		if (recipient.name) {
			object.name = recipient.name;
		}

		if (recipient.email) {
			object.email = recipient.email;
		}

		if (recipient.importedFromAddressbook) {
			object.importedFromAddressbook = true;
		}

		if (recipient.isInvestor) {
			object.isInvestor = true;
		}

		return object;
	},

	normalizeAttendeeObject: (attendeeId, attendee, company) => {
		if (!attendee.isGuest) {
			attendee.isGuest = false;
		}

		if (!attendee.status) {
			attendee.status = null;
		}

		if (!attendee.responseStatus) {
			attendee.responseStatus = null;
		}

		if (!attendee.haveResponded) {
			attendee.haveResponded = false;
		}

		if (!attendee.notified) {
			attendee.notified = false;
		}

		if (company && company.representatives && Array.isArray(company.representatives)) {
			const representative = company.representatives.find((obj) => obj.id === attendeeId);

			if (representative) {
				attendee.roles = representative.roles;
			}
		}

		return attendee;
	},

	findProxyByAttendeeId: (attendees, proxies, attendeeId) => {
		let proxy;

		if (!proxies) {
			return;
		}

		if (!attendees) {
			return;
		}

		for (const proxyId of Object.keys(proxies)) {
			const attendeeIds = proxies[proxyId];

			if (attendeeIds.includes(attendeeId)) {
				proxy = attendees[proxyId];
				break;
			}
		}

		return proxy;
	},

	isAttendeeProxy: (attendee, proxies) => {
		if (!attendee) {
			return false;
		}

		if (attendee.isProxy) {
			return true;
		}

		return proxies && proxies[attendee.userId] && proxies[attendee.userId].length > 0 ? true : false;
	},

	isAttendeeModerator: (attendeeId, moderators) => {
		if (!attendeeId || !moderators) {
			return;
		}

		const attendeeIsModerator = moderators.some((moderatorId) => attendeeId === moderatorId);

		return attendeeIsModerator;
	},

	isAttendingByProxy: (attendeeId, proxies) => {
		let result = false;

		if (!proxies) {
			return result;
		}

		for (const attendeeIds of Object.values(proxies)) {
			if (attendeeIds && attendeeIds.includes(attendeeId)) {
				result = true;
				break;
			}
		}

		return result;
	},

	isProxyConfirmedForAttendee: (proxy, attendeeId) => {
		if (!proxy) {
			return false;
		}

		const isProxyConfirmed =
			proxy.confirmedProxyFor &&
			proxy.confirmedProxyFor[attendeeId] &&
			proxy.confirmedProxyFor[attendeeId].confirmed === true;
		return isProxyConfirmed;
	},

	isProxyConfirmedForAtLeastOneAttendee: (proxy) => {
		if (!proxy) {
			return false;
		}

		if (!proxy.confirmedProxyFor) {
			return false;
		}

		let confirmedForAtLeastOne = false;

		for (const confirmation of Object.values(proxy.confirmedProxyFor)) {
			if (confirmation.confirmed === true) {
				confirmedForAtLeastOne = true;
				break;
			}
		}

		return confirmedForAtLeastOne;
	},

	getAttendeeHasProxyDocument: (attendee) => {
		if (!attendee) {
			return false;
		}

		const { proxyDocuments } = attendee;

		if (!proxyDocuments) {
			return false;
		}

		const hasDocuments = Object.keys(proxyDocuments).length > 0;

		return hasDocuments;
	}
};

module.exports = obj;
