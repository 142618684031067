module.exports = [
	{
		id: '25a1657b-5101-4144-9a48-bf188ecb897f',
		proposal: 'Öppna mötet',
		locked: true,
		active: true,
		generatedByTemplate: true,
		internalType: 'open',
		internalData: {}
	},
	{
		id: '607b9259-5f39-45ad-a2f1-a42a26658645',
		proposal: 'Föregående möte',
		locked: false,
		active: false,
		generatedByTemplate: true,
		internalType: 'previousMeeting',
		internalData: {}
	},
	{
		id: '1094036f-004b-4e23-8ba0-19e360cf9d46',
		proposal: 'Boka nästa möte',
		locked: false,
		active: false,
		postItem: true,
		generatedByTemplate: true,
		internalType: 'nextMeeting',
		internalData: {}
	},
	{
		id: '773d52f4-da8c-4422-9e72-a15f9623da03',
		proposal: 'Avsluta mötet',
		locked: true,
		active: true,
		postItem: true,
		generatedByTemplate: true,
		internalType: 'close',
		internalData: {}
	}
];
