const { checkFirstFourCharInRange } = require('../helpers/helpers.helper');
const { DEFAULT_EMAIL_LANGUAGE } = require('../constants.json');

const obj = {
	generateInvonoId: () => {
		const ALPHABET = '0123456789';

		const ID_LENGTH = 4;

		const generate = function () {
			let rtn = '';
			for (let i = 0; i < ID_LENGTH; i++) {
				rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
			}
			return rtn;
		};

		return 'A-' + generate() + '-' + generate();
	},

	formatSsn: (ssn) => {
		if (!ssn) {
			ssn = '';
		}

		if (typeof ssn === 'number') {
			ssn = ssn.toString();
		}
		ssn = ssn.trim().replace('-', '');

		if (ssn.length === 10 && !checkFirstFourCharInRange(ssn)) {
			const d = new Date();
			const year = d.getFullYear() - 2000;
			const twoFirst = ssn.substring(0, 2);

			if (year < twoFirst) {
				ssn = '19' + ssn;
			} else {
				ssn = '20' + ssn;
			}
		}
		return ssn;
	},

	validateEmail: (email) => {
		const re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return email && re.test(email);
	},

	/**
	 * Validates a swedish org number.
	 * @param {string} input - The org number as a string.
	 * @see {@link https://gist.github.com/peppelorum/5856691}
	 * @exports validateOrgNumber
	 */
	validateOrgNumber: (input) => {
		// Check valid length & form
		if (!input) {
			return false;
		}
		if (typeof input === 'number') {
			input = input.toString();
		}

		input = input.trim().replace('-', '');
		if (input.length === 10) {
			const lastTwo = input.slice(2, 4);
			if (lastTwo < 20) {
				return false;
			}

			if (input.indexOf('-') === -1) {
				input = input.slice(0, 6) + '-' + input.slice(6);
			}

			if (!input.match(/^(\d{2})(\d{2})(\d{2})-(\d{4})|(\d{4})(\d{2})(\d{2})-(\d{4})$/)) {
				return false;
			}

			// Clean input
			input = input.replace('-', '');

			// Declare variables
			const d = new Date(
				RegExp.$1 ? RegExp.$1 : RegExp.$5,
				(RegExp.$2 ? RegExp.$2 : RegExp.$6) - 1,
				RegExp.$3 ? RegExp.$3 : RegExp.$7
			); /* eslint-disable-line */
			let sum = 0;
			const numdigits = input.length;
			const parity = numdigits % 2;
			let i;
			let digit;

			// Check valid date
			if (Object.prototype.toString.call(d) !== '[object Date]' || isNaN(d.getTime())) {
				return false;
			}

			// Check luhn algorithm
			for (i = 0; i < numdigits; i = i + 1) {
				digit = parseInt(input.charAt(i), 10);
				if (i % 2 === parity) {
					digit *= 2;
				}
				if (digit > 9) {
					digit -= 9;
				}
				sum += digit;
			}
			return sum % 10 === 0;
		}
		return false;
	},

	/**
	 * Validates a swedish person number.
	 * @param {string} input - The person number as a string.
	 * @see {@link https://gist.github.com/peppelorum/5856691}
	 * @exports validatePersonNumber
	 */
	validatePersonNumber: (input) => {
		// Check valid length & form
		if (!input) {
			return false;
		}
		if (typeof input === 'number') {
			input = input.toString();
		}

		input = input.trim().replace('-', '');

		if (input.length === 12 || input.length === 10) {
			if (input.length === 12) {
				const lastTwo = input.slice(4, 6);
				if (lastTwo > 12) {
					return false;
				}
				if (input.indexOf('-') === -1) {
					input = input.slice(0, 8) + '-' + input.slice(8);
				}
				if (!input.match(/^(\d{2})(\d{2})(\d{2})-(\d{4})|(\d{4})(\d{2})(\d{2})-(\d{4})$/)) {
					return false;
				}

				// Clean input
				input = input.replace('-', '');

				// Remove the 19 or 20 in the beginning.
				input = input.substring(2);
			}

			if (input.length === 10) {
				const lastTwo = input.slice(2, 4);
				if (lastTwo > 12) {
					return false;
				}
				if (input.indexOf('-') === -1) {
					input = input.slice(0, 6) + '-' + input.slice(6);
				}
				if (!input.match(/^(\d{2})(\d{2})(\d{2})-(\d{4})|(\d{4})(\d{2})(\d{2})-(\d{4})$/)) {
					return false;
				}

				// Clean input
				input = input.replace('-', '');
			}

			// Declare variables
			const d = new Date(
				RegExp.$1 ? RegExp.$1 : RegExp.$5,
				(RegExp.$2 ? RegExp.$2 : RegExp.$6) - 1,
				RegExp.$3 ? RegExp.$3 : RegExp.$7
			); /* eslint-disable-line */
			let sum = 0;
			const numdigits = input.length;
			const parity = numdigits % 2;
			let i;
			let digit;

			// Check valid date
			if (Object.prototype.toString.call(d) !== '[object Date]' || isNaN(d.getTime())) {
				return false;
			}

			// Check luhn algorithm
			for (i = 0; i < numdigits; i = i + 1) {
				digit = parseInt(input.charAt(i), 10);
				if (i % 2 === parity) {
					digit *= 2;
				}
				if (digit > 9) {
					digit -= 9;
				}
				sum += digit;
			}
			return sum % 10 === 0;
		}
		return false;
	},

	formatOrgNumber: (orgNumber) => {
		if (typeof orgNumber === 'number') {
			orgNumber = orgNumber.toString();
		}
		orgNumber = orgNumber && orgNumber.trim().replace('-', '');
		return orgNumber;
	},

	formatSsnPretty: (ssn) => {
		ssn = ssn && ssn.trim().replace('-', '');

		if (!ssn) {
			return '';
		}

		if (ssn.length === 12) {
			return ssn.replace(/([0-9]{8})([X0-9]{4})/, (match, p1, p2) => {
				return [p1, p2].join('-');
			});
		} else if (ssn.length === 10) {
			return ssn.replace(/([0-9]{6})([X0-9]{4})/, (match, p1, p2) => {
				return [p1, p2].join('-');
			});
		} else {
			return ssn;
		}
	},

	formatOrgPretty: (org) => {
		return obj.formatSsnPretty(org);
	},

	formatEmail: (email) => {
		return email && typeof email === 'string' && email.toLowerCase().trim();
	},

	maskSsn: (ssn) => {
		if (ssn.length === 12) {
			return ssn.replace(/([0-9]{8})([X0-9]{4})/, (match, p1) => {
				return `${p1}XXXX`;
			});
		} else if (ssn.length === 10) {
			return ssn.replace(/([0-9]{6})([X0-9]{4})/, (match, p1) => {
				return `${p1}XXXX`;
			});
		} else {
			return ssn;
		}
	},

	isUuid: (str) => {
		return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(str);
	},

	sleep: (seconds) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, 1000 * seconds);
		});
	},

	// Fastställ användarens språk. Används för att skicka ut e-post på rätt språk.
	// Om användaren inte har gjort något vl av språk på sin användarprofil används
	// värdet av defaultLanguage.
	defineUserLanguage: (user, defaultLanguage = DEFAULT_EMAIL_LANGUAGE) => {
		let language = DEFAULT_EMAIL_LANGUAGE;

		if (user && user.siteSettings && user.siteSettings.language) {
			language = user.siteSettings.language.substr(0, 2);
		} else if (defaultLanguage) {
			language = defaultLanguage;
		}

		return language;
	},

	// Fastställ bolagets språk. Används för att skicka ut e-post på rätt språk.
	// Om bolaget inte har något region-objekt så används istället värdet av defaultLanguage.
	defineCompanyLanguage: (company, defaultLanguage = DEFAULT_EMAIL_LANGUAGE) => {
		let language = DEFAULT_EMAIL_LANGUAGE;

		if (company && company.region && company.region.language && company.region.language.main) {
			language = company.region.language.main;
		} else if (defaultLanguage) {
			language = defaultLanguage;
		}

		return language;
	},

	generateRandomString: (length = 6) => {
		const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVW1234567890';

		let rtn = '';
		for (let i = 0; i < length; i++) {
			rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
		}
		return rtn;
	},

	generateCode: () => {
		return (Math.floor(Math.random() * 9000) + 1000).toString();
	},

	//Checks if an JS object is empty.
	isEmpty: (obj) => {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	},

	isJson: (str) => {
		try {
			return JSON.parse(str) ? true : false;
		} catch (e) {
			return false;
		}
	},
	moveInArray: (array, fromIndex, toIndex) => {
		const element = array[fromIndex];
		array.splice(fromIndex, 1);
		array.splice(toIndex, 0, element);
	}
};

module.exports = obj;
