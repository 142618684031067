const uuid = require('uuid');
const { formatEmail } = require('./helpers');

const obj = {
	/**
	 *
	 * @param {user} user
	 * @param {string} email
	 * @param {boolean} setDefault
	 * @returns {user}
	 */
	addEmailToUser: (user, email, setDefault) => {
		if (!user.emails) {
			user.emails = [];
		}

		const emailId = uuid.v1();
		user.emails.push({
			id: emailId,
			email: formatEmail(email),
			verified: false
		});

		if (setDefault) {
			user.defaultEmail = emailId;
		}

		return user;
	},

	getEmail: (user, companyId) => {
		if (!companyId) {
			return obj.getDefaultEmail(user);
		}

		const companyEmailRef = user.companyEmail && user.companyEmail[companyId];

		if (!companyEmailRef) {
			return obj.getDefaultEmail(user);
		}

		const emailObject = user.emails.find((email) => email.id === companyEmailRef) || obj.getDefaultEmail(user);
		return emailObject && emailObject.email;
	},

	getDefaultEmail: (user) => {
		const emailObject = user.emails && user.emails.find((email) => email.id === user.defaultEmail);
		return emailObject && emailObject.email;
	}
};

module.exports = obj;
